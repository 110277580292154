import React from 'react';
// import router from 'next/router';
import Head from '@/componentWebs/head';
// import styles from './index.less';

// @page
class Exception extends React.PureComponent {
  render() {
    const { dataSite, intl } = this.props;
    return (
      <>
        <Head
          title={intl.formatMessage({ id: 'title404' })}
          dataSite={dataSite}
          description={intl.formatMessage({ id: 'des404' })}
        />
        <div className="wrapper">
          <section className="error-page">
            <div className="error-page-type">404</div>
            <h1>{intl.formatMessage({ id: 'title404' })}</h1>
            <h2>{intl.formatMessage({ id: 'des404' })}</h2>
            <h2>
              <span>{intl.formatMessage({ id: 'back' })}</span>
              <a href="/">{intl.formatMessage({ id: 'home' })}</a>
            </h2>
          </section>
        </div>
      </>
    );
  }
}

export default Exception;
